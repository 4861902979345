import React from 'react';

import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaTelegram
} from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  FooterContent,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  ExternalLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  DisclosureText,
  SocialIcons,
  SocialIconLink
} from './FooterElements';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterContent>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>About Us</FooterLinkTitle>
                <FooterLink to='/sign-up'>How it works</FooterLink>
                <FooterLink to='/'>Testimonials</FooterLink>
                <FooterLink to='/'>Careers</FooterLink>
                <FooterLink to='/'>Investors</FooterLink>
                <FooterLink to='/'>Terms of Service</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Contact Us</FooterLinkTitle>
                <FooterLink to='/'>Contact</FooterLink>
                <FooterLink to='/'>Support</FooterLink>
                <FooterLink to='/'>Destinations</FooterLink>
                <FooterLink to='/'>Sponsorships</FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Videos</FooterLinkTitle>
                <FooterLink to='/'>Submit Video</FooterLink>
                <FooterLink to='/'>Ambassadors</FooterLink>
                <FooterLink to='/'>Agency</FooterLink>
                <FooterLink to='/'>Influencer</FooterLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Social Media</FooterLinkTitle>
                <ExternalLink href='https://t.me/StepInDSHIT' target='_blank' rel='noopener noreferrer'> Telegram</ExternalLink>
                <ExternalLink href='https://twitter.com/DogShitSolana' target = '_blank' rel='noopener noreferrer'>Twitter</ExternalLink>
                <FooterLink to='/'>Dexscreener</FooterLink>
                <FooterLink to='/'>Birdeye</FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo to='/' onClick={toggleHome}>
                Dog Shit
              </SocialLogo>
              <WebsiteRights>Dog Shit © 2024 All rights reserved. </WebsiteRights>
              <SocialIcons>
                <SocialIconLink href='/' target='_blank' aria-label='Facebook'>
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink href='/' target='_blank' aria-label='Instagram'>
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  href='//www.youtube.com/'
                  target='_blank'
                  aria-label='Youtube'
                  rel='noopener noreferrer'
                >
                  <FaYoutube />
                </SocialIconLink>
                <SocialIconLink
                  target='_blank'
                  aria-label='Twitter'
                  href='https://twitter.com/DogShitSolana'
                >
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink href='https://t.me/StepInDSHIT' target='_blank' aria-label='Telegram'>
                  <FaTelegram />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterContent>
        <DisclosureText>
        DISCLOSURE: Dog Shit Token is a social experiment. By purchasing Dog Shit Token, you agree that you are not purchasing a security or investment contract and you agree to hold the team harmless and not liable for any losses or taxes you may incur. Although DSHIT is a community driven DeFi Ecosystem and not a registered digital currency, always make sure that you are in compliance with local laws and regulations before you make any purchase. Cryptocurrencies are not legal tender and are not investments.
        </DisclosureText>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
