import React from 'react';
import Icon1 from '../../images/undraw_mello_otq1.svg';
import Icon2 from '../../images/undraw_financial_data_re_p0fl.svg';
import Icon3 from '../../images/undraw_programming_re_kg9v.svg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Tokenomics</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>LP Tokens</ServicesH2>
          <ServicesP>
            Burned
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Total Supply</ServicesH2>
          <ServicesP>
            1 Billion
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Reserved Tokens </ServicesH2>
          <ServicesP>
            dev/marketing/CEX+R&D
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
