import React from 'react';

export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Premier Shitcoin',
  headline: 'Powered by Solana',
  description: (
    <>
      Zero tax on transactions, freeze authority<br />
      revoked, and mint authority revoked.
    </>
  ),
  buttonLabel: 'Buy Now',
  buttonUrl: 'https://raydium.io/swap/', // Updated with the Raydium link
  imgStart: false,
  img: require('../../images/600_431.png'),
  alt: 'DogShitting1',
  dark: true,
  primary: true,
  darkText: false
};
    
export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'It\'s Dog Shit',
  headline: '💩 step in it',
  description: 
    "you're literally buying Dog Shit.",
  buttonLabel: 'Dog Shit',
  buttonUrl: 'https://raydium.io/swap/', // Updated with the Raydium link
  imgStart: true,
  img: require('../../images/dog_taking_shit.png'),
  alt: 'dogshitting2',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Available on',
  headline: 'Raydium',
  description: (
    <>
      Token Address: <br />
      6nLn7mybbJ94AMtRASpkXE4TGjKpHeZ1LbgTZqCNnsfG
    </>
  ),
  buttonLabel: 'Buy Now',
  buttonUrl: 'https://raydium.io/swap/', // Updated with the Raydium link
  imgStart: false,
  img: require('../../images/Raydium (RAY).svg'),
  alt: 'Raydium',
  dark: false,
  primary: false,
  darkText: true
};
