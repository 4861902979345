  import styled from 'styled-components';

  let timelineElements = [
      {
        id: 1,
        title: "DSHIT Token Launch",
        location: "Phase I",
        description:
          "Join us as we launch the DSHIT token, marking the beginning of our journey. Our website and token are deployed, ready to revolutionize the crypto space.",
        date: "Phase I",
        icon: "work",
      },
      {
        id: 2,
        title: "DSHIT Marketing Campaign Kickoff",
        location: "Phase 1.5",
        description:
          "Our marketing efforts are in full swing! We're listed on Dexscreener, Birdeye, and Dextools, while actively growing our community through Telegram and Twitter.",
        date: "Phase 1.5",
        icon: "work",
      },
      {
        id: 3,
        title: "DSHIT Community Outreach",
        location: "Phase II",
        description:
          "Engage with our vibrant community through interactive competitions with reward initiatives. Join us as we celebrate the diverse talents and contributions of DSHIT holders.",
        date: "Phase II",
        icon: "work",
      },
      {
        id: 4,
        title: "DSHIT Listings",
        location: "Phase 2.5",
        description:
        "We'll secured listings on prominent platforms such as CoinGekco and Coinmarketcap, enhancing visibility and accessibility for our growing community.",
        date: "Phase 2.5",
        icon: "school",
      },
      {
        id: 5,
        title: "CEX listings",
        location: "Phase III",
        description:
        "We're making strides towards broader accessibility by aiming for listings on Centralized Exchanges (CEX), ensuring easier access for users worldwide. ",
        date: "Phase 3",
        icon: "school",
      },
      {
        id: 6,
        title: "DSHIT Protocol R&D",
        location: "Phase 3.5",
        description:
        "Our team is dedicated to advancing the DSHIT protocol through rigorous research and development. Explore our efforts to expand DSHIT's functionality and interoperability across different blockchain networks. ",
        date: "Phase 3",
        icon: "school",
      },
    ];
    
    export default timelineElements;

    export const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px) {
      font-size: 2rem;
    }
  `;

  export const ServicesContainer = styled.div`
    background: #010606;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  `;

